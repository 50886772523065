import React, { useState, useEffect } from "react";
import "./OnionBuy.css"; // Assuming a CSS file for marble buyers' styles
import { useAuth } from "./AuthContext";
import { Link } from "react-router-dom";
import Whyglobal from "../Components/Whyglobal";
import BuyerSide from "./BuyerSidebuyer";

const MarbleBuyers = [
  // Marble Buyers
  {
    name: "Rajesh Kumar",
    email: "rajesh.kumar@gmail.com",
    mobileNumber: "9876543210",
    locationandDate: "Jaipur, 28-11-2024",
    lookingFor: "Looking for premium marble for flooring.",
    productOrService: "Marble Flooring",
    quantity: 100,
    unit: "sq meters",
    _id: "unique_id_marble_buyer_1",
  },
  {
    name: "Deepika Sharma",
    email: "deepika.sharma@gmail.com",
    mobileNumber: "8765432109",
    locationandDate: "Mumbai, 27-11-2024",
    lookingFor: "Need white marble for kitchen countertops.",
    productOrService: "White Marble",
    quantity: 50,
    unit: "sq meters",
  },
  {
    name: "Amit Yadav",
    email: "amit.yadav@gmail.com",
    mobileNumber: "7654321098",
    locationandDate: "Delhi, 27-11-2024",
    lookingFor: "Seeking colorful marble slabs for decorative purposes.",
    productOrService: "Decorative Marble Slabs",
    quantity: 75,
    unit: "sq meters",
  },
  {
    name: "Sunita Mehta",
    email: "sunita.mehta@gmail.com",
    mobileNumber: "6543210987",
    locationandDate: "Bangalore, 25-11-2024",
    lookingFor: "Interested in marble tiles for flooring.",
    productOrService: "Marble Tiles",
    quantity: 120,
    unit: "sq meters",
  },
  {
    name: "Vinod Kumar",
    email: "vinod.kumar@gmail.com",
    mobileNumber: "5432109876",
    locationandDate: "Chennai, 25-11-2024",
    lookingFor: "Looking for high-quality marble for building projects.",
    productOrService: "Building Marble",
    quantity: 150,
    unit: "sq meters",
  },
  {
    name: "Anjali Gupta",
    email: "anjali.gupta@gmail.com",
    mobileNumber: "4321098765",
    locationandDate: "Kolkata, 24-11-2024",
    lookingFor: "Order for polished marble for interior design.",
    productOrService: "Polished Marble",
    quantity: 100,
    unit: "sq meters",
  },
  {
    name: "Ravi Patel",
    email: "ravi.patel@gmail.com",
    mobileNumber: "3210987654",
    locationandDate: "Ahmedabad, 21-11-2024",
    lookingFor: "Bulk purchase of beige marble slabs for walls.",
    productOrService: "Beige Marble Slabs",
    quantity: 200,
    unit: "sq meters",
  },
  {
    name: "Nisha Rani",
    email: "nisha.rani@gmail.com",
    mobileNumber: "2109876543",
    locationandDate: "Lucknow, 20-11-2024",
    lookingFor: "Need premium marble for hotel construction.",
    productOrService: "Premium Marble",
    quantity: 250,
    unit: "sq meters",
  },
];

const MarbleBuy = () => {
  const { userId } = useAuth();
  const [userIsPremium, setUserIsPremium] = useState(false);
  const [shownNumbers, setShownNumbers] = useState([]);
  const [leadsViewed, setLeadsViewed] = useState(0);

  useEffect(() => {
    if (!userId) return;

    const storedViewedLeads =
      JSON.parse(localStorage.getItem(`marbleViewedLeads_${userId}`)) || [];
    setShownNumbers(storedViewedLeads);

    fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/getUserWithPremiumStatus/${userId}`
    )
      .then((response) => response.json())
      .then((data) => {
        setUserIsPremium(data.isPremium);
        setLeadsViewed(data.leadsViewed);
      })
      .catch((error) => {
        console.error("Error fetching user information:", error);
      });
  }, [userId]);

  const toggleNumber = async (index) => {
    if (!userIsPremium) return;

    if (leadsViewed >= 25) {
      alert("You have reached the limit of 25 leads viewed per month.");
      return;
    }

    if (shownNumbers.includes(index)) return;

    const updatedShownNumbers = [...shownNumbers, index];
    setShownNumbers(updatedShownNumbers);

    localStorage.setItem(
      `marbleViewedLeads_${userId}`,
      JSON.stringify(updatedShownNumbers)
    );

    const buyer = MarbleBuyers[index];

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/incrementLeadsViewed/${userId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            buyerName: buyer.name,
            mobileNo: buyer.mobileNumber,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setLeadsViewed(data.leadsViewed);
      } else {
        const errorData = await response.json();
        console.error("Error incrementing leads viewed:", errorData.error);
      }
    } catch (error) {
      console.error("Error incrementing leads viewed:", error.message);
    }
  };

  return (
    <>
      <div className="container">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to={"/"}>Home</Link>
          </li>
          <li className="breadcrumb-item active">Marble Buyers</li>
        </ol>
      </div>
      <div className="containersx">
        <div style={{ width: "200px", padding: "10px" }} className="browsers">
          <h3>Browse by State</h3>
          <ul className="flt-list cust-scroll" id="state-lists">
            <li>
              <Link to="#">All India</Link>
            </li>
            <li>
              <Link to="#">Maharashtra</Link>
            </li>
            <li>
              <Link to="#">Tamil Nadu</Link>
            </li>
            <li>
              <Link to="#">Delhi</Link>
            </li>
            <li>
              <Link to="#">Gujarat</Link>
            </li>
          </ul>
          <ul className="flt-list cust-scroll">
            <h3>All Varieties</h3>
            <li>
              <Link to="#">Marble Flooring</Link>
            </li>
            <li>
              <Link to="#">White Marble</Link>
            </li>
            <li>
              <Link to="#">Decorative Marble Slabs</Link>
            </li>
            <li>
              <Link to="#">Polished Marble</Link>
            </li>
            <li>
              <Link to="#">Building Marble</Link>
            </li>
            <li>
              <Link to="#">Beige Marble Slabs</Link>
            </li>
          </ul>
        </div>
        <div className="buyers">
          {MarbleBuyers.map((buyer, index) => (
            <div key={index} className="buyer-card">
              <h2 style={{ color: "blue" }}>{buyer.lookingFor}</h2>
              <p>
                <strong>Buyer Name:</strong> {buyer.name}
              </p>
              <p>
                <strong>Quantity:</strong> {buyer.quantity} {buyer.unit}
              </p>
              <p>
                <strong>Location & Date:</strong> {buyer.locationandDate}
              </p>
              <p>
                <strong>Mobile No.:</strong>{" "}
                {userIsPremium
                  ? shownNumbers.includes(index)
                    ? buyer.mobileNumber
                    : "********"
                  : "********"}
              </p>
              <button
                className="detail-btn"
                onClick={() => toggleNumber(index)}
                disabled={!userIsPremium}
              >
                <div className="btns-imp">
                  <Link to={"/login"}>
                    <button className="detailing-btn">View More</button>
                  </Link>
                  <Link to={"/register-Company"}>
                    <button className="regis-btn">Register Now</button>
                  </Link>
                </div>
              </button>
            </div>
          ))}
        </div>
        <BuyerSide />
      </div>
      <Whyglobal />
    </>
  );
};

export default MarbleBuy;
