import React from "react";
import { Helmet } from "react-helmet";
import "./commn.css";

const HomeSupplies = () => {
  <Helmet>
    <title>GlobalB2Bmart has the best bag suppliers in India for you.</title>
    <meta
      name="description"
      content="Discover top-quality bag suppliers in India with GlobalB2Bmart. Connect with trusted manufacturers and suppliers offering a wide range of bags at competitive prices	"
    />
  </Helmet>;
  const products = [
    {
      title: "Artificial & Decorative Candles",
      imageSrc: "/assets/hom1.jpg",
      links: [
        { name: "Candle", url: "#" },
        { name: "Decorative Candles", url: "#" },
        { name: "Wax Candles", url: "#" },
        { name: "Votive Candle", url: "#" },
        { name: "View More", url: "#" },
      ],
    },
    {
      title: "Bags",
      imageSrc: "/assets/hom2.webp",
      links: [
        { name: "Woven Bags", url: "#" },
        { name: "Shopping Bags", url: "#" },
        { name: "Ladies Bags", url: "#" },
        { name: "Cotton Bags", url: "#" },
        { name: "View More", url: "#" },
      ],
    },
    {
      title: "Bar Accessories",
      imageSrc: "/assets/hom3.jpg",
      links: [
        { name: "Wine Coolers", url: "#" },
        { name: "Beer Mug", url: "#" },
        { name: "Wine Holder", url: "#" },
        { name: "Drinking Horn", url: "#" },
        { name: "View More", url: "#" },
      ],
    },
    {
      title: "Buckets, Mugs & Storage Bins",
      imageSrc: "/assets/hom4.webp",
      links: [
        { name: "Bucket", url: "#" },
        { name: "Dustbins", url: "#" },
        { name: "Plastic Basket", url: "#" },
        { name: "Plastic Bins", url: "#" },
        { name: "View More", url: "#" },
      ],
    },
    {
      title: "Candle Stands & Candelabra",
      imageSrc: "/assets/hom5.avif",
      links: [
        { name: "Candle Holder", url: "#" },
        { name: "Brass Stand", url: "#" },
        { name: "Candle Pillars", url: "#" },
        { name: "View More", url: "#" },
      ],
    },
    {
      title: "Chandeliers & Wall Sconces",
      imageSrc: "/assets/hom6.jpg",
      links: [
        { name: "Led Lantern", url: "#" },
        { name: "Hanging Lamps", url: "#" },
        { name: "Ceiling Lamps", url: "#" },
        { name: "Pendant Lamps", url: "#" },
        { name: "View More", url: "#" },
      ],
    },
    {
      title: "Outdoor Furniture",
      imageSrc: "/assets/hom7.jpg",
      links: [
        { name: "Patio Sets", url: "#" },
        { name: "Hammocks", url: "#" },
        { name: "Outdoor Chairs", url: "#" },
        { name: "Fire Pits", url: "#" },
        { name: "View More", url: "#" },
      ],
    },
    {
      title: "Home Office",
      imageSrc: "/assets/hom8.jpg",
      links: [
        { name: "Desks", url: "#" },
        { name: "Office Chairs", url: "#" },
        { name: "Bookshelves", url: "#" },
        { name: "Desk Lamps", url: "#" },
        { name: "View More", url: "#" },
      ],
    },
    {
      title: "Dining Room",
      imageSrc: "/assets/hom9.jpeg",
      links: [
        { name: "Dining Tables", url: "#" },
        { name: "Dining Chairs", url: "#" },
        { name: "Tableware", url: "#" },
        { name: "Dining Sets", url: "#" },
        { name: "View More", url: "#" },
      ],
    },
    {
      title: "Storage Solutions",
      imageSrc: "/assets/hom10.webp",
      links: [
        { name: "Closet Organizers", url: "#" },
        { name: "Storage Bins", url: "#" },
        { name: "Shelving Units", url: "#" },
        { name: "Cabinets", url: "#" },
        { name: "View More", url: "#" },
      ],
    },
  ];

  return (
    <>
      <div className="pagetitle">
        <h1>Home Supplies Trade Directory & Networking Platform</h1>
      </div>
      <div className="l1-section">
        <div className="container">
          <div className="row l1-box-wrap">
            {products.map((product, index) => (
              <div key={index} className="col-4">
                <div className="l1-box">
                  <h2 className="l1-heading">
                    <a href="#">{product.title}</a>
                  </h2>
                  <div className="d-flex">
                    <div>
                      <figure className="cp" onClick={() => {}}>
                        <img
                          src={product.imageSrc}
                          alt={product.title}
                          width={95}
                          height={95}
                          loading="lazy"
                        />
                      </figure>
                    </div>
                    <div>
                      <ul>
                        {product.links.map((link, linkIndex) => (
                          <li key={linkIndex}>
                            <a href={link.url}>{link.name}</a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="content-page">
        <p style={{ padding: "15px" }}>
          Discover a diverse range of home supplies including artificial
          candles, bags, bar accessories, and more. Connect with suppliers,
          manufacturers, and wholesalers globally.
        </p>
      </div>
    </>
  );
};

export default HomeSupplies;
