import React, { useState, useEffect } from "react";
import "./OnionBuy.css"; // Assuming a CSS file for mattress buyers' styles
import { useAuth } from "./AuthContext";
import { Link } from "react-router-dom";
import Whyglobal from "../Components/Whyglobal";
import BuyerSide from "./BuyerSidebuyer";

const MattressBuyers = [
  // Mattress Buyers
  {
    name: "Ajay Sharma",
    email: "ajay.sharma@gmail.com",
    mobileNumber: "9876543210",
    locationandDate: "Delhi, 11-12-2024",
    lookingFor: "Looking for comfortable king-size mattresses.",
    productOrService: "King-size Mattress",
    quantity: 30,
    unit: "pieces",
    _id: "unique_id_mattress_buyer_1",
  },
  {
    name: "Neha Verma",
    email: "neha.verma@gmail.com",
    mobileNumber: "9988776655",
    locationandDate: "Mumbai, 11-12-2024",
    lookingFor: "Seeking high-quality queen-size mattresses.",
    productOrService: "Queen-size Mattress",
    quantity: 50,
    unit: "pieces",
    _id: "unique_id_mattress_buyer_2",
  },
  {
    name: "Ravi Singh",
    email: "ravi.singh@gmail.com",
    mobileNumber: "9888776655",
    locationandDate: "Bangalore, 10-12-2024",
    lookingFor: "Looking for double-size mattresses with memory foam.",
    productOrService: "Double-size Mattress",
    quantity: 40,
    unit: "pieces",
    _id: "unique_id_mattress_buyer_3",
  },
  {
    name: "Priya Mehta",
    email: "priya.mehta@gmail.com",
    mobileNumber: "9776655443",
    locationandDate: "Chennai, 09-12-2024",
    lookingFor: "Seeking comfortable single-size mattresses for guest rooms.",
    productOrService: "Single-size Mattress",
    quantity: 20,
    unit: "pieces",
    _id: "unique_id_mattress_buyer_4",
  },
  {
    name: "Sunil Kumar",
    email: "sunil.kumar@gmail.com",
    mobileNumber: "9098765432",
    locationandDate: "Kolkata, 08-12-2024",
    lookingFor: "Looking for orthopedic king-size mattresses.",
    productOrService: "King-size Mattress",
    quantity: 35,
    unit: "pieces",
    _id: "unique_id_mattress_buyer_5",
  },
  {
    name: "Suman Reddy",
    email: "suman.reddy@gmail.com",
    mobileNumber: "9456732100",
    locationandDate: "Hyderabad, 07-12-2024",
    lookingFor: "Searching for eco-friendly queen-size mattresses.",
    productOrService: "Queen-size Mattress",
    quantity: 25,
    unit: "pieces",
    _id: "unique_id_mattress_buyer_6",
  },
  {
    name: "Ankit Gupta",
    email: "ankit.gupta@gmail.com",
    mobileNumber: "9834123456",
    locationandDate: "Noida, 05-12-2024",
    lookingFor: "Interested in luxury double-size mattresses.",
    productOrService: "Double-size Mattress",
    quantity: 60,
    unit: "pieces",
    _id: "unique_id_mattress_buyer_7",
  },
  {
    name: "Geeta Sharma",
    email: "geeta.sharma@gmail.com",
    mobileNumber: "9944231245",
    locationandDate: "Pune, 04-12-2024",
    lookingFor: "Looking for comfortable single-size mattresses for apartment.",
    productOrService: "Single-size Mattress",
    quantity: 15,
    unit: "pieces",
    _id: "unique_id_mattress_buyer_8",
  },
  {
    name: "Mohit Yadav",
    email: "mohit.yadav@gmail.com",
    mobileNumber: "9092345678",
    locationandDate: "Ahmedabad, 03-12-2024",
    lookingFor: "Searching for foam-based king-size mattresses.",
    productOrService: "King-size Mattress",
    quantity: 45,
    unit: "pieces",
    _id: "unique_id_mattress_buyer_9",
  },
  {
    name: "Sakshi Patel",
    email: "sakshi.patel@gmail.com",
    mobileNumber: "9888654321",
    locationandDate: "Surat, 02-12-2024",
    lookingFor: "Interested in luxury queen-size mattresses.",
    productOrService: "Queen-size Mattress",
    quantity: 55,
    unit: "pieces",
    _id: "unique_id_mattress_buyer_10",
  },
  {
    name: "Ajay Sharma",
    email: "ajay.sharma@gmail.com",
    mobileNumber: "9876543210",
    locationandDate: "Delhi, 11-12-2024",
    lookingFor: "Looking for comfortable king-size mattresses.",
    productOrService: "King-size Mattress",
    quantity: 30,
    unit: "pieces",
    _id: "unique_id_mattress_buyer_1",
  },
  {
    name: "Priya Yadav",
    email: "priya.yadav@gmail.com",
    mobileNumber: "8765432109",
    locationandDate: "Mumbai, 10-12-2024",
    lookingFor: "Interested in orthopedic mattresses for a hotel chain.",
    productOrService: "Orthopedic Mattress",
    quantity: 50,
    unit: "pieces",
  },
  {
    name: "Ravi Mehta",
    email: "ravi.mehta@gmail.com",
    mobileNumber: "7654321098",
    locationandDate: "Bangalore, 10-12-2024",
    lookingFor: "Looking for high-quality memory foam mattresses.",
    productOrService: "Memory Foam Mattress",
    quantity: 100,
    unit: "pieces",
  },
  {
    name: "Shweta Verma",
    email: "shweta.verma@gmail.com",
    mobileNumber: "6543210987",
    locationandDate: "Chennai, 08-12-2024",
    lookingFor: "Comfortable and durable mattresses for my store.",
    productOrService: "Durable Mattress",
    quantity: 150,
    unit: "pieces",
  },
  {
    name: "Vishal Pandey",
    email: "vishal.pandey@gmail.com",
    mobileNumber: "5432109876",
    locationandDate: "Ahmedabad, 07-12-2024",
    lookingFor: "High-end mattresses for luxury hotels.",
    productOrService: "Luxury Mattress",
    quantity: 200,
    unit: "pieces",
  },
  {
    name: "Neha Agarwal",
    email: "neha.agarwal@gmail.com",
    mobileNumber: "4321098765",
    locationandDate: "Kolkata, 17-11-2024",
    lookingFor: "Looking for eco-friendly mattresses for retail.",
    productOrService: "Eco-friendly Mattress",
    quantity: 500,
    unit: "pieces",
  },
  {
    name: "Sandeep Singh",
    email: "sandeep.singh@gmail.com",
    mobileNumber: "3210987654",
    locationandDate: "Jaipur, 16-11-2024",
    lookingFor: "Looking for bulk mattresses for a large project.",
    productOrService: "Bulk Mattress",
    quantity: 1000,
    unit: "pieces",
  },
  {
    name: "Aarti Sharma",
    email: "aarti.sharma@gmail.com",
    mobileNumber: "2109876543",
    locationandDate: "Lucknow, 15-11-2024",
    lookingFor: "Need comfortable mattresses for a new hotel.",
    productOrService: "Hotel Mattress",
    quantity: 300,
    unit: "pieces",
  },
];

const MattressBuy = () => {
  const { userId } = useAuth();
  const [userIsPremium, setUserIsPremium] = useState(false);
  const [shownNumbers, setShownNumbers] = useState([]);
  const [leadsViewed, setLeadsViewed] = useState(0);

  useEffect(() => {
    if (!userId) return;

    const storedViewedLeads =
      JSON.parse(localStorage.getItem(`mattressViewedLeads_${userId}`)) || [];
    setShownNumbers(storedViewedLeads);

    fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/getUserWithPremiumStatus/${userId}`
    )
      .then((response) => response.json())
      .then((data) => {
        setUserIsPremium(data.isPremium);
        setLeadsViewed(data.leadsViewed);
      })
      .catch((error) => {
        console.error("Error fetching user information:", error);
      });
  }, [userId]);

  const toggleNumber = async (index) => {
    if (!userIsPremium) return;

    if (leadsViewed >= 25) {
      alert("You have reached the limit of 25 leads viewed per month.");
      return;
    }

    if (shownNumbers.includes(index)) return;

    const updatedShownNumbers = [...shownNumbers, index];
    setShownNumbers(updatedShownNumbers);

    localStorage.setItem(
      `mattressViewedLeads_${userId}`,
      JSON.stringify(updatedShownNumbers)
    );

    const buyer = MattressBuyers[index];

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/incrementLeadsViewed/${userId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            buyerName: buyer.name,
            mobileNo: buyer.mobileNumber,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setLeadsViewed(data.leadsViewed);
      } else {
        const errorData = await response.json();
        console.error("Error incrementing leads viewed:", errorData.error);
      }
    } catch (error) {
      console.error("Error incrementing leads viewed:", error.message);
    }
  };

  return (
    <>
      <div className="container">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to={"/"}>Home</Link>
          </li>
          <li className="breadcrumb-item active">Mattress Buyers</li>
        </ol>
      </div>
      <div className="containersx">
        <div style={{ width: "200px", padding: "10px" }} className="browsers">
          <h3>Browse by State</h3>
          <ul className="flt-list cust-scroll" id="state-lists">
            <li>
              <Link to="#">All India</Link>
            </li>
            <li>
              <Link to="#">Maharashtra</Link>
            </li>
            <li>
              <Link to="#">Tamil Nadu</Link>
            </li>
            <li>
              <Link to="#">Delhi</Link>
            </li>
            <li>
              <Link to="#">Gujarat</Link>
            </li>
          </ul>
          <ul className="flt-list cust-scroll">
            <h3>All Varieties</h3>
            <li>
              <Link to="#">King-size Mattress</Link>
            </li>
            <li>
              <Link to="#">Orthopedic Mattress</Link>
            </li>
            <li>
              <Link to="#">Memory Foam Mattress</Link>
            </li>
            <li>
              <Link to="#">Durable Mattress</Link>
            </li>
            <li>
              <Link to="#">Luxury Mattress</Link>
            </li>
            <li>
              <Link to="#">Eco-friendly Mattress</Link>
            </li>
            <li>
              <Link to="#">Hotel Mattress</Link>
            </li>
            <li>
              <Link to="#">Bulk Mattress</Link>
            </li>
          </ul>
        </div>
        <div className="buyers">
          {MattressBuyers.map((buyer, index) => (
            <div key={index} className="buyer-card">
              <h2 style={{ color: "blue" }}>{buyer.lookingFor}</h2>
              <p>
                <strong>Buyer Name:</strong> {buyer.name}
              </p>
              <p>
                <strong>Quantity:</strong> {buyer.quantity} {buyer.unit}
              </p>
              <p>
                <strong>Location & Date:</strong> {buyer.locationandDate}
              </p>
              <p>
                <strong>Mobile No.:</strong>{" "}
                {userIsPremium
                  ? shownNumbers.includes(index)
                    ? buyer.mobileNumber
                    : "********"
                  : "********"}
              </p>
              <button
                className="detail-btn"
                onClick={() => toggleNumber(index)}
                disabled={!userIsPremium}
              >
                <div className="btns-imp">
                  <Link to={"/login"}>
                    <button className="detailing-btn">View More</button>
                  </Link>
                  <Link to={"/register-Company"}>
                    <button className="regis-btn">Register Now</button>
                  </Link>
                </div>
              </button>
            </div>
          ))}
        </div>
        <BuyerSide />
      </div>
      <Whyglobal />
    </>
  );
};

export default MattressBuy;
