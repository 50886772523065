import React from "react";
import { Helmet } from "react-helmet";
import "./commn.css";

const Fashion = () => {
  const products = [
    {
      title: "Beachwear",
      imageSrc: "/assets/app1.jpg",
      links: [
        { name: "Swimsuits", url: "#" },
        { name: "Cover-ups", url: "#" },
        { name: "Boardshorts", url: "#" },
        { name: "Beach Dresses", url: "#" },
        { name: "View More", url: "#" },
      ],
    },
    {
      title: "Women's Clothing",
      imageSrc: "/assets/app2.jpg",
      links: [
        { name: "Dresses", url: "#" },
        { name: "Skirts", url: "#" },
        { name: "Blouses", url: "#" },
        { name: "Jumpsuits", url: "#" },
        { name: "View More", url: "#" },
      ],
    },
    {
      title: "Hand Gloves & Mittens",
      imageSrc: "/assets/app3.jpg",
      links: [
        { name: "Leather Gloves", url: "#" },
        { name: "Winter Mittens", url: "#" },
        { name: "Touchscreen Gloves", url: "#" },
        { name: "Driving Gloves", url: "#" },
        { name: "View More", url: "#" },
      ],
    },
    {
      title: "Embroidered Apparel & Garments",
      imageSrc: "/assets/app4.jpg",
      links: [
        { name: "Embroidered Jackets", url: "#" },
        { name: "Embroidered Skirts", url: "#" },
        { name: "Embroidered Blouses", url: "#" },
        { name: "Embroidered Shawls", url: "#" },
        { name: "View More", url: "#" },
      ],
    },
    {
      title: "Fashion Garments",
      imageSrc: "/assets/app5.webp",
      links: [
        { name: "Evening Gowns", url: "#" },
        { name: "Cocktail Dresses", url: "#" },
        { name: "Blazers", url: "#" },
        { name: "Jumpsuits", url: "#" },
        { name: "View More", url: "#" },
      ],
    },

    {
      title: "Industrial Clothing & Work Wear",
      imageSrc: "/assets/app9.jpg",
      links: [
        { name: "Coveralls", url: "#" },
        { name: "High-Visibility Vests", url: "#" },
        { name: "Work Boots", url: "#" },
        { name: "Hard Hats", url: "#" },
        { name: "View More", url: "#" },
      ],
    },
    {
      title: "Infant & Baby Clothing",
      imageSrc: "/assets/app8.jpg",
      links: [
        { name: "Baby Onesies", url: "#" },
        { name: "Baby T-Shirts", url: "#" },
        { name: "Baby Leggings", url: "#" },
        { name: "Baby Dresses", url: "#" },
        { name: "View More", url: "#" },
      ],
    },
    {
      title: "Kids Clothing & Dresses",
      imageSrc: "/assets/app10.jpg",
      links: [
        { name: "Kids T-Shirts", url: "#" },
        { name: "Kids Jeans", url: "#" },
        { name: "Kids Dresses", url: "#" },
        { name: "Kids Jackets", url: "#" },
        { name: "View More", url: "#" },
      ],
    },
    {
      title: "Animal Clothing & Accessories",
      imageSrc: "/assets/app12.avif",
      links: [
        { name: "Pet Coats", url: "#" },
        { name: "Horse Blankets", url: "#" },
        { name: "Dog Sweaters", url: "#" },
        { name: "Cat Harnesses", url: "#" },
        { name: "View More", url: "#" },
      ],
    },
  ];

  return (
    <>
      <Helmet>
        <title>
          Best clothing and apparel suppliers in india | globalb2bmart
        </title>{" "}
        {/* Title Tag */}
        <meta
          name="description"
          content="Globalb2bmart is the best b2b portal in india and is ready to connect you with some big clothing and apparel suppliers in india"
        />{" "}
        {/* Meta Description */}
      </Helmet>

      <div className="pagetitle">
        <h1>
          Your Premier Destination for Apparel & Fashion Industry Connections &
          B2B Trade Hub
        </h1>
      </div>
      <div className="l1-section">
        <div className="container">
          <div className="row l1-box-wrap">
            {products.map((product, index) => (
              <div key={index} className="col-4">
                <div className="l1-box">
                  <h2 className="l1-heading">
                    <a href="#">{product.title}</a>
                  </h2>
                  <div className="d-flex">
                    <div>
                      <figure className="cp" onClick={() => {}}>
                        <img
                          src={product.imageSrc}
                          alt={product.title}
                          width={95}
                          height={95}
                          loading="lazy"
                        />
                      </figure>
                    </div>
                    <div>
                      <ul>
                        {product.links.map((link, linkIndex) => (
                          <li key={linkIndex}>
                            <a href={link.url}>{link.name}</a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="content-page">
        <p style={{ padding: "15px" }}>
          Explore a wide range of apparel and fashion products including
          beachwear, women's clothing, hand gloves, fashion garments, and more.
          Connect with suppliers, manufacturers, and wholesalers worldwide.
        </p>
      </div>
      <div class="category-content">
        <h2
          style={{
            color: "orange",
            textAlign: "center",
            margin: "20px 0px",
          }}
        >
          Best clothing suppliers & apparel suppliers in india | globalb2bmart
        </h2>
        <p class="section-content">
          Hello and welcome to GlobalB2BMart, the best destination to connect
          with the best clothing and apparel suppliers in India. We provide a
          regular platform for businesses to avail quality dress and style items
          procured from reliable manufacturers and exporters. Whether you are a
          retailer, distributor, or designer, GlobalB2BMart ensures access to
          the finest suppliers and manufacturers in the industry.
        </p>

        <p class="section-heading">
          <strong>Clothing Suppliers</strong>
        </p>
        <p class="section-content">
          GlobalB2BMart connects you with high-level clothing suppliers offering
          a wide range of products to cater to your business requirements. From
          casual to formal wear, our suppliers provide you with high-quality
          clothing in various styles, sizes, and textures. With our verified
          suppliers, you can ensure that your inventory is up to the most
          in-demand trend patterns and buyer demands.
        </p>

        <p class="section-heading">
          <strong>Apparel suppliers</strong>
        </p>
        <p class="section-content">
          Research the apparel suppliers on GlobalB2BMart for stylish and strong
          attire. Be it ethnic wear, western outfits, or handcrafted clothing,
          our foundation assures the best delivery and critical analysis. Choose
          GlobalB2BMart to elevate your business with the best clothing
          providers in India.
        </p>

        <p class="section-heading">
          <strong>cloth manufacturers</strong>
        </p>
        <p class="section-content">
          Our cloth manufacturers at GlobalB2BMart work with driving material
          makers who have real experience in furnishing great materials and
          textures. Whether it is unrefined components for your image or
          prepared-to-join texture, our material producers ensure predominant
          workmanship and advancement.
        </p>
        <p class="section-heading">
          <strong>Garments Manufacturers</strong>
        </p>
        <p class="section-content">
          GlobalB2BMart shows a broad organization of pieces of garment
          manufacturers who take special care of different market needs. From
          relaxed wear to extravagance pieces of clothing, our providers center
          around quality and customization. Our articles of clothing makers
          guarantee that organizations can get the best items, custom-made to
          their particular prerequisites.
        </p>
        <p class="section-heading">
          <strong>Ladies Readymade Garments</strong>
        </p>
        <p class="section-content">
          If you're looking for fashionable and affordable ladies readymade
          garments, GlobalB2BMart caters to your needs. Our suppliers provide a
          vast range of readymade clothing, such as dresses, tops, sarees, and
          much more, which is perfect for retail shops and stores. Stay ahead of
          the curve with our exceptional women readymade garment suppliers.
        </p>
        <p class="section-heading">
          <strong>Kurti Exporters</strong>
        </p>
        <p class="section-content">
          Associate with reliable kurti exporters on GlobalB2BMart to source
          exquisite and flexible kurtis. From traditional designs to modern
          samples, our exporters ensure high-quality products that appeal to
          international markets. As a leading platform, we facilitate
          procurement from the finest kurti exporters in India.
        </p>
      </div>
    </>
  );
};

export default Fashion;
