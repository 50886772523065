import React from "react";
import "./agricul.css";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
const Agriculture = () => {
  return (
    <>
      <Helmet>
        <title>Best suppliers of agriculture and farm tools in India</title>
        <meta
          name="description"
          content="We are GlobalB2BMart best B2B portal in India, providing the best suppliers and buyers of agriculture and farm tools. GlobalB2BMart believes in trust and quality"
        />
      </Helmet>
      <div class="pagetitle">
        <h1>Agriculture Trade Directory & Networking Platform</h1>
      </div>
      <div className="l1-section">
        <div className="container">
          <div className="row l1-box-wrap">
            <div className="col-4">
              <div className="l1-box">
                <h2 className="l1-heading">
                  <Link to="vegetable-supplier">Vegetables</Link>
                </h2>
                <div className="d-flex">
                  <div>
                    <figure className="cp" onclick="/">
                      <img
                        src="./assets/agri1.jpg"
                        alt="Vegetables"
                        width={95}
                        height={95}
                        loading="lazy"
                      />
                    </figure>
                  </div>
                  <div>
                    <ul>
                      <li>
                        <Link to="/onion-seeds-dehydrated-onions-white">
                          Onions
                        </Link>
                        {/* <a href="#">
                                                  Onion
                                                </a> */}
                      </li>
                      <li>
                        <Link to="/potatosupplier">potato</Link>
                      </li>
                      <li>
                        <a href="#">Spinach</a>
                      </li>
                      <li>
                        <a href="#">Bell Pepper</a>
                      </li>
                      <li>
                        <a href="#">View More</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="l1-box">
                <h2 className="l1-heading">
                  <a href="#">Tractor &amp; Tractor Parts</a>
                </h2>
                <div className="d-flex">
                  <div>
                    <figure className="cp" onclick="/">
                      <img
                        src="./assets/agri2.webp"
                        alt="Tractor & Tractor Parts"
                        width={95}
                        height={95}
                        loading="lazy"
                      />
                    </figure>
                  </div>
                  <div>
                    <ul>
                      <li>
                        <a href="#">Tractor Engines</a>
                      </li>
                      <li>
                        <a href="#">Tractor Seats</a>
                      </li>
                      <li>
                        <a href="#">Tractor Radiators</a>
                      </li>
                      <li>
                        <a href="#">Tractor Axles</a>
                      </li>
                      <li>
                        <a href="#">View More</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="l1-box">
                <h2 className="l1-heading">
                  <a href="#">Livestock &amp; Pet Animals</a>
                </h2>
                <div className="d-flex">
                  <div>
                    <figure className="cp" onclick="/">
                      <img
                        src="./assets/agri3.avif"
                        alt="Livestock & Pet Animals"
                        width={95}
                        height={95}
                        loading="lazy"
                      />
                    </figure>
                  </div>
                  <div>
                    <ul>
                      <li>
                        <a href="#">Sheep</a>
                      </li>
                      <li>
                        <a href="#">Goat</a>
                      </li>
                      <li>
                        <a href="#">Pigs</a>
                      </li>
                      <li>
                        <a href="#">Horse Feed</a>
                      </li>
                      <li>
                        <a href="/">View More</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="l1-box">
                <h2 className="l1-heading">
                  <a href="/">Legumes</a>
                </h2>
                <div className="d-flex">
                  <div>
                    <link rel="preload" as="image" href="/" />
                    <figure className="cp" onclick="/'">
                      <img
                        src="./assets/agri4.webp"
                        alt="Legumes"
                        width={95}
                        height={95}
                        loading="lazy"
                      />
                    </figure>
                  </div>
                  <div>
                    <ul>
                      <li>
                        <a href="/">Soybeans</a>
                      </li>
                      <li>
                        <a href="/">Lentils</a>
                      </li>
                      <li>
                        <a href="/">Kidney Beans</a>
                      </li>
                      <li>
                        <a href="/">Black Beans</a>
                      </li>
                      <li>
                        <a href="/">View More</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="l1-box">
                <h2 className="l1-heading">
                  <a href="/">Farming Solutions &amp; Services</a>
                </h2>
                <div className="d-flex">
                  <div>
                    <link rel="preload" as="image" href="/" />
                    <figure className="cp" onclick="location.href='/">
                      <img
                        src="./assets/agri5.jpg"
                        alt="Farming Solutions & Services"
                        width={95}
                        height={95}
                        loading="lazy"
                      />
                    </figure>
                  </div>
                  <div>
                    <ul>
                      <li>
                        <a href="/">Crop Protection Services</a>
                      </li>
                      <li>
                        <a href="/">Soil Conservation</a>
                      </li>
                      <li>
                        <a href="/">Irrigation Solutions</a>
                      </li>
                      <li>
                        <a href="/">Precision Agriculture</a>
                      </li>
                      <li>
                        <a href="/">View More</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="l1-box">
                <h2 className="l1-heading">
                  <a href="/">Agricultural Implements &amp; Machinery</a>
                </h2>
                <div className="d-flex">
                  <div>
                    <link rel="preload" as="image" href="/" />
                    <figure className="cp" onclick="/">
                      <img
                        src="./assets/agri6.webp"
                        alt="Agricultural Implements & Machinery"
                        width={95}
                        height={95}
                        loading="lazy"
                      />
                    </figure>
                  </div>
                  <div>
                    <ul>
                      <li>
                        <a href="/">Seed Drills</a>
                      </li>
                      <li>
                        <a href="/">Tractors</a>
                      </li>
                      <li>
                        <a href="/">Sprayers</a>
                      </li>
                      <li>
                        <a href="/">Combine Harvesters</a>
                      </li>
                      <li>
                        <a href="/">View More</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="l1-box">
                <h2 className="l1-heading">
                  <a href="/">Plant Nutrition &amp; Soil Enhancers</a>
                </h2>
                <div className="d-flex">
                  <div>
                    <link rel="preload" as="image" href="/" />
                    <figure className="cp" onclick="/">
                      <img
                        src="./assets/agri7.jpg"
                        alt="Plant Nutrition & Soil Enhancers"
                        width={95}
                        height={95}
                        loading="lazy"
                      />
                    </figure>
                  </div>
                  <div>
                    <ul>
                      <li>
                        <a href="#">Nitrogen Fertilizers</a>
                      </li>
                      <li>
                        <a href="#">Organic Compost</a>
                      </li>
                      <li>
                        <a href="#">Growth Stimulants</a>
                      </li>
                      <li>
                        <a href="#">Soil Conditioners</a>
                      </li>
                      <li>
                        <a href="#">View More</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="l1-box">
                <h2 className="l1-heading">
                  <a href="#">Cereals &amp; Pulses</a>
                </h2>
                <div className="d-flex">
                  <div>
                    <link rel="preload" as="image" href="#" />
                    <figure className="cp" onclick="/">
                      <img
                        src="./assets/agri8.jpg"
                        alt="Cereals & Pulses"
                        width={95}
                        height={95}
                        loading="lazy"
                      />
                    </figure>
                  </div>
                  <div>
                    <ul>
                      <li>
                        <a href="#">Corn</a>
                      </li>
                      <li>
                        <a href="#">Barley</a>
                      </li>
                      <li>
                        <a href="#">Lentils</a>
                      </li>
                      <li>
                        <a href="#">Chickpeas</a>
                      </li>
                      <li>
                        <a href="#">View More</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="l1-box">
                <h2 className="l1-heading">
                  <a href="/">Floral Arrangements &amp; Greenery</a>
                </h2>
                <div className="d-flex">
                  <div>
                    <link rel="preload" as="image" href="/" />
                    <figure className="cp" onclick="/">
                      <img
                        src="./assets/agri9.jpg"
                        alt="Floral Arrangements & Greenery"
                        width={95}
                        height={95}
                        loading="lazy"
                      />
                    </figure>
                  </div>
                  <div>
                    <ul>
                      <li>
                        <a href="#">Orchids</a>
                      </li>
                      <li>
                        <a href="#">Floral Bouquets</a>
                      </li>
                      <li>
                        <a href="#">Roses</a>
                      </li>
                      <li>
                        <a href="#">Indoor Plants</a>
                      </li>
                      <li>
                        <a href="#">View More</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="l1-box">
                <h2 className="l1-heading">
                  <a href="#">Gardening Essentials &amp; Outdoor Equipment</a>
                </h2>
                <div className="d-flex">
                  <div>
                    <link rel="preload" as="image" href="/" />
                    <figure className="cp" onclick="/">
                      <img
                        src="./assets/agri10.jpg"
                        alt="Gardening Essentials & Outdoor Equipment"
                        width={95}
                        height={95}
                        loading="lazy"
                      />
                    </figure>
                  </div>
                  <div>
                    <ul>
                      <li>
                        <a href="#">Grass Shears</a>
                      </li>
                      <li>
                        <a href="#">Plant Containers</a>
                      </li>
                      <li>
                        <a href="#">Hand Rake</a>
                      </li>
                      <li>
                        <a href="#">Pruning Shears</a>
                      </li>
                      <li>
                        <a href="#">View More</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="l1-box">
                <h2 className="l1-heading">
                  <a href="#">Water Management &amp; Irrigation Solutions</a>
                </h2>
                <div className="d-flex">
                  <div>
                    <link rel="preload" as="image" href="/" />
                    <figure className="cp" onclick="/">
                      <img
                        src="./assets/agri11.jpg"
                        alt="Water Management & Irrigation Solutions"
                        width={95}
                        height={95}
                        loading="lazy"
                      />
                    </figure>
                  </div>
                  <div>
                    <ul>
                      <li>
                        <a href="#">Water Pumps</a>
                      </li>
                      <li>
                        <a href="#">Drainage Systems</a>
                      </li>
                      <li>
                        <a href="#">Drip Irrigation</a>
                      </li>
                      <li>
                        <a href="#">Sprinkler Heads</a>
                      </li>
                      <li>
                        <a href="#">View More</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="l1-box">
                <h2 className="l1-heading">
                  <a href="/">Exotic &amp; Juicy Fruits</a>
                </h2>
                <div className="d-flex">
                  <div>
                    <link rel="preload" as="image" href="/" />
                    <figure className="cp" onclick="/">
                      <img
                        src="./assets/agri13.jpg"
                        alt="Exotic & Juicy Fruits"
                        width={95}
                        height={95}
                        loading="lazy"
                      />
                    </figure>
                  </div>
                  <div>
                    <ul>
                      <li>
                        <a href="#">Pineapple</a>
                      </li>
                      <li>
                        <a href="#">Papaya</a>
                      </li>
                      <li>
                        <a href="#">Kiwi</a>
                      </li>
                      <li>
                        <a href="#">Dragon Fruit</a>
                      </li>
                      <li>
                        <a href="#">View More</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="content-page">
            <p>
              GlobalB2BMart is the leading Agriculture B2B Marketplace and
              Agriculture Business Directory, offering comprehensive business
              listings of Agriculture Suppliers, Manufacturers, Exporters,
              Wholesalers, and Traders worldwide. Our extensive and verified
              database includes detailed information about Agriculture
              Companies, a diverse catalog of Agriculture Products, and
              up-to-date Agriculture Price Lists and Quotations.
            </p>
          </div> */}

          <div class="category-content">
            <h2
              style={{
                color: "orange",
                textAlign: "center",
                margin: "20px 0px",
              }}
            >
              Agricultural Machinery Suppliers | Agriculture Equipment Suppliers
            </h2>
            <p class="section-content">
              On the online platform of GlobalB2BMart, one comes across trusted
              suppliers for agriculture equipment and agricultural machinery,
              from the places of India and further global locations. With
              GlobalB2BMart, source equipment easily and efficiently of highest
              quality, through which farmers and other companies in the
              agriculture industry could execute their operations more
              effectively.
            </p>

            <p class="section-heading">
              <strong>Agricultural Machinery Suppliers</strong>
            </p>
            <p class="section-content">
              Find a large network of agricultural machinery suppliers on
              GlobalB2BMart, offering the right solutions for your farming
              operations. From seeders to harvesters, our suppliers ensure
              durable and efficient machinery that meets the demands of modern
              agriculture, helping you maximize output with minimal effort.
            </p>

            <p class="section-heading">
              <strong>Agriculture Equipment Suppliers</strong>
            </p>
            <p class="section-content">
              When it comes to finding reliable agriculture equipment suppliers,
              GlobalB2BMart should be your first choice. Our verified suppliers
              provide everything from irrigation systems to soil preparation
              tools, designed to enhance farming practices while ensuring
              sustainability and innovation in agriculture.
            </p>

            <p class="section-heading">
              <strong>Farm Tractor Suppliers</strong>
            </p>
            <p class="section-content">
              GlobalB2BMart brings under one roof the leading farm tractor
              suppliers around the world to meet rising demand for powerful and
              strong tractors. Whether looking for compact models for a small
              farm or heavy equipment for large-scale operations, our suppliers
              deliver high-quality units to meet diverse agricultural
              requirements.
            </p>

            <p class="section-heading">
              <strong>Vegetable Suppliers in India</strong>
            </p>
            <p class="section-content">
              Partner with the best vegetable suppliers in India via
              GlobalB2BMart. Our website connects you with reliable suppliers of
              fresh, high-quality vegetables for wholesalers, retailers, and
              exporters to ensure a stable supply chain.
            </p>

            <p class="section-heading">
              <strong>Legume Suppliers in India</strong>
            </p>
            <p class="section-content">
              You never have to look too hard for trusted legume suppliers in
              India. GlobalB2BMart features suppliers that provide nutrient-rich
              legumes such as lentils, chickpeas, and beans to help you source
              premium products for both domestic and international markets.
            </p>

            <p class="section-heading">
              <strong>Fruits Suppliers in India</strong>
            </p>
            <p class="section-content">
              GlobalB2BMart has access to all kinds of fruit suppliers in India,
              ranging from seasonal to tropical fruits. These verified suppliers
              ensure freshness and quality, satisfying the increasing fruit
              demand from the retail, hospitality, and export markets.
            </p>

            <p class="section-heading">
              <strong>White Cotton Bales</strong>
            </p>
            <p class="section-content">
              GlobalB2BMart provides you with the reputed white cotton bale
              suppliers for superior-grade cotton for textile production and
              industrial purposes. Our portal will make the purchasing process
              easy for you for buying raw materials for your business.
            </p>

            <p class="section-content">
              GlobalB2BMart is here to connect you with the best supplier or
              buyer of the agricultural commodity and commodities market. Come
              and start exploring today, and take your business to a higher
              level!"
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Agriculture;
