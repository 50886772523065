import React, { useState, useEffect } from "react";
import "./OnionBuy.css";
import { useAuth } from "./AuthContext";
import { Link } from "react-router-dom";
import Whyglobal from "../Components/Whyglobal";
import BuyerSide from "./BuyerSidebuyer";

const HoneyBuyers = [
  // Honey Buyers
  {
    name: "Ankit Sharma",
    email: "ankit.sharma@gmail.com",
    mobileNumber: "9876543210",
    locationandDate: "Delhi, 28-11-2024",
    lookingFor: "Looking for pure and organic honey.",
    productOrService: "Organic Honey",
    quantity: 50,
    unit: "kg",
    _id: "unique_id_honey_buyer_1",
  },
  {
    name: "Priya Gupta",
    email: "priya.gupta@gmail.com",
    mobileNumber: "8765432109",
    locationandDate: "Mumbai, 28-11-2024",
    lookingFor: "Interested in bulk honey supply for my shop.",
    productOrService: "Bulk Honey",
    quantity: 100,
    unit: "kg",
  },
  {
    name: "Ravi Patel",
    email: "ravi.patel@gmail.com",
    mobileNumber: "7654321098",
    locationandDate: "Ahmedabad, 27-11-2024",
    lookingFor: "Looking for natural honey from local farms.",
    productOrService: "Natural Honey",
    quantity: 200,
    unit: "kg",
  },
  {
    name: "Shweta Mehta",
    email: "shweta.mehta@gmail.com",
    mobileNumber: "6543210987",
    locationandDate: "Bangalore, 26-11-2024",
    lookingFor: "Premium quality honey for commercial use.",
    productOrService: "Premium Honey",
    quantity: 150,
    unit: "kg",
  },
  {
    name: "Vishal Kumar",
    email: "vishal.kumar@gmail.com",
    mobileNumber: "5432109876",
    locationandDate: "Kolkata, 25-11-2024",
    lookingFor: "Need honey for a wellness brand.",
    productOrService: "Honey for Wellness",
    quantity: 250,
    unit: "kg",
  },
  {
    name: "Neha Agarwal",
    email: "neha.agarwal@gmail.com",
    mobileNumber: "4321098765",
    locationandDate: "Chennai, 24-11-2024",
    lookingFor: "Looking for honey for packaging and selling.",
    productOrService: "Honey for Packaging",
    quantity: 500,
    unit: "kg",
  },
  {
    name: "Sandeep Kumar",
    email: "sandeep.kumar@gmail.com",
    mobileNumber: "3210987654",
    locationandDate: "Jaipur, 22-11-2024",
    lookingFor: "Bulk purchase of organic honey for export.",
    productOrService: "Bulk Organic Honey",
    quantity: 1000,
    unit: "kg",
  },
  {
    name: "Aarti Singh",
    email: "aarti.singh@gmail.com",
    mobileNumber: "2109876543",
    locationandDate: "Lucknow, 21-11-2024",
    lookingFor: "Pure honey for retail distribution.",
    productOrService: "Pure Honey",
    quantity: 300,
    unit: "kg",
  },
];

const HoneyBuy = () => {
  const { userId } = useAuth();
  const [userIsPremium, setUserIsPremium] = useState(false);
  const [shownNumbers, setShownNumbers] = useState([]);
  const [leadsViewed, setLeadsViewed] = useState(0);

  useEffect(() => {
    if (!userId) return;

    const storedViewedLeads =
      JSON.parse(localStorage.getItem(`honeyViewedLeads_${userId}`)) || [];
    setShownNumbers(storedViewedLeads);

    fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/getUserWithPremiumStatus/${userId}`
    )
      .then((response) => response.json())
      .then((data) => {
        setUserIsPremium(data.isPremium);
        setLeadsViewed(data.leadsViewed);
      })
      .catch((error) => {
        console.error("Error fetching user information:", error);
      });
  }, [userId]);

  const toggleNumber = async (index) => {
    if (!userIsPremium) return;

    if (leadsViewed >= 25) {
      alert("You have reached the limit of 25 leads viewed per month.");
      return;
    }

    if (shownNumbers.includes(index)) return;

    const updatedShownNumbers = [...shownNumbers, index];
    setShownNumbers(updatedShownNumbers);

    localStorage.setItem(
      `honeyViewedLeads_${userId}`,
      JSON.stringify(updatedShownNumbers)
    );

    const buyer = HoneyBuyers[index];

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/incrementLeadsViewed/${userId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            buyerName: buyer.name,
            mobileNo: buyer.mobileNumber,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setLeadsViewed(data.leadsViewed);
      } else {
        const errorData = await response.json();
        console.error("Error incrementing leads viewed:", errorData.error);
      }
    } catch (error) {
      console.error("Error incrementing leads viewed:", error.message);
    }
  };

  return (
    <>
      <div className="container">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to={"/"}>Home</Link>
          </li>
          <li className="breadcrumb-item active">Honey Buyers</li>
        </ol>
      </div>
      <div className="containersx">
        <div style={{ width: "200px", padding: "10px" }} className="browsers">
          <h3>Browse by State</h3>
          <ul className="flt-list cust-scroll" id="state-lists">
            <li>
              <Link to="#">All India</Link>
            </li>
            <li>
              <Link to="#">Maharashtra</Link>
            </li>
            <li>
              <Link to="#">Tamil Nadu</Link>
            </li>
            <li>
              <Link to="#">Delhi</Link>
            </li>
            <li>
              <Link to="#">Gujarat</Link>
            </li>
          </ul>
          <ul className="flt-list cust-scroll">
            <h3>All Varieties</h3>
            <li>
              <Link to="#">Organic Honey</Link>
            </li>
            <li>
              <Link to="#">Bulk Honey</Link>
            </li>
            <li>
              <Link to="#">Natural Honey</Link>
            </li>
            <li>
              <Link to="#">Premium Honey</Link>
            </li>
            <li>
              <Link to="#">Honey for Wellness</Link>
            </li>
            <li>
              <Link to="#">Honey for Packaging</Link>
            </li>
          </ul>
        </div>
        <div className="buyers">
          {HoneyBuyers.map((buyer, index) => (
            <div key={index} className="buyer-card">
              <h2 style={{ color: "blue" }}>{buyer.lookingFor}</h2>
              <p>
                <strong>Buyer Name:</strong> {buyer.name}
              </p>
              <p>
                <strong>Quantity:</strong> {buyer.quantity} {buyer.unit}
              </p>
              <p>
                <strong>Location & Date:</strong> {buyer.locationandDate}
              </p>
              <p>
                <strong>Mobile No.:</strong>{" "}
                {userIsPremium
                  ? shownNumbers.includes(index)
                    ? buyer.mobileNumber
                    : "********"
                  : "********"}
              </p>
              <button
                className="detail-btn"
                onClick={() => toggleNumber(index)}
                disabled={!userIsPremium}
              >
                <div className="btns-imp">
                  <Link to={"/login"}>
                    <button className="detailing-btn">View More</button>
                  </Link>
                  <Link to={"/register-Company"}>
                    <button className="regis-btn">Register Now</button>
                  </Link>
                </div>
              </button>
            </div>
          ))}
        </div>
        <BuyerSide />
      </div>
      <Whyglobal />
    </>
  );
};

export default HoneyBuy;
