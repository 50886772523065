import React from "react";
import "./commn.css";

const Furniture = () => {
  const products = [
    {
      title: "Living Room Furniture",
      imageSrc: "./assets/fu1.jpeg",
      links: [
        { name: "Sofas", url: "#" },
        { name: "Coffee Tables", url: "#" },
        { name: "TV Stands", url: "#" },
        { name: "Bookshelves", url: "#" },
        { name: "View More", url: "#" },
      ],
    },
    {
      title: "Office Furniture",
      imageSrc: "./assets/fu2.jpeg",
      links: [
        { name: "Office Desks", url: "#" },
        { name: "Office Chairs", url: "#" },
        { name: "Conference Tables", url: "#" },
        { name: "Filing Cabinets", url: "#" },
        { name: "View More", url: "#" },
      ],
    },
    {
      title: "Outdoor Furniture",
      imageSrc: "./assets/fu3.jpeg",
      links: [
        { name: "Patio Sets", url: "#" },
        { name: "Outdoor Benches", url: "#" },
        { name: "Hammocks", url: "#" },
        { name: "Garden Chairs", url: "#" },
        { name: "View More", url: "#" },
      ],
    },
    {
      title: "Kitchen Furniture",
      imageSrc: "./assets/fu4.jpeg",
      alt: "Kitchen Furniture",
      links: [
        { name: "Kitchen Islands", url: "#" },
        { name: "Bar Stools", url: "#" },
        { name: "Pantry Cabinets", url: "#" },
        { name: "Dining Tables", url: "#" },
        { name: "View More", url: "#" },
      ],
    },
    {
      title: "Dining Room Furniture",
      imageSrc: "./assets/fu5.jpeg",
      links: [
        { name: "Dining Tables", url: "#" },
        { name: "Dining Chairs", url: "#" },
        { name: "Buffets", url: "#" },
        { name: "Dining Sets", url: "#" },
        { name: "View More", url: "#" },
      ],
    },
    {
      title: "Home Office Furniture",
      imageSrc: "./assets/fu6.jpeg",
      links: [
        { name: "Computer Desks", url: "#" },
        { name: "Ergonomic Chairs", url: "#" },
        { name: "Bookcases", url: "#" },
        { name: "Desk Lamps", url: "#" },
        { name: "View More", url: "#" },
      ],
    },
    {
      title: "Home Theater Furniture",
      imageSrc: "./assets/fu7.jpeg",
      links: [
        { name: "Entertainment Centers", url: "#" },
        { name: "Media Storage", url: "#" },
        { name: "Recliners", url: "#" },
        { name: "Speaker Stands", url: "#" },
        { name: "View More", url: "#" },
      ],
    },
    {
      title: "Entryway Furniture",
      imageSrc: "./assets/fu8.jpeg",
      links: [
        { name: "Console Tables", url: "#" },
        { name: "Coat Racks", url: "#" },
        { name: "Shoe Storage", url: "#" },
        { name: "Entryway Benches", url: "#" },
        { name: "View More", url: "#" },
      ],
    },
    {
      title: "Nursery Furniture",
      imageSrc: "./assets/fu9.jpeg",
      links: [
        { name: "Cribs", url: "#" },
        { name: "Changing Tables", url: "#" },
        { name: "Nursery Rocking Chairs", url: "#" },
        { name: "Storage Baskets", url: "#" },
        { name: "View More", url: "#" },
      ],
    },
    {
      title: "Library Furniture",
      imageSrc: "./assets/fu10.jpeg",
      alt: "Library Furniture",
      links: [
        { name: "Bookshelves", url: "#" },
        { name: "Reading Chairs", url: "#" },
        { name: "Library Tables", url: "#" },
        { name: "Magazine Racks", url: "#" },
        { name: "View More", url: "#" },
      ],
    },
    {
      title: "Reception Furniture",
      imageSrc: "./assets/fu11.jpeg",
      links: [
        { name: "Reception Desks", url: "#" },
        { name: "Waiting Room Chairs", url: "#" },
        { name: "Coffee Tables", url: "#" },
        { name: "Guest Sofas", url: "#" },
        { name: "View More", url: "#" },
      ],
    },
    {
      title: "Garage Furniture",
      imageSrc: "./assets/fu12.jpeg",
      links: [
        { name: "Workbenches", url: "#" },
        { name: "Storage Cabinets", url: "#" },
        { name: "Tool Racks", url: "#" },
        { name: "Utility Shelves", url: "#" },
        { name: "View More", url: "#" },
      ],
    },
    {
      title: "Outdoor Furniture",
      imageSrc: "./assets/fu13.jpeg",
      links: [
        { name: "Patio Sets", url: "#" },
        { name: "Outdoor Benches", url: "#" },
        { name: "Hammocks", url: "#" },
        { name: "Garden Tables", url: "#" },
        { name: "View More", url: "#" },
      ],
    },
    {
      title: "Office Furniture",
      imageSrc: "./assets/fu14.jpeg",
      links: [
        { name: "Office Desks", url: "#" },
        { name: "Ergonomic Chairs", url: "#" },
        { name: "Filing Cabinets", url: "#" },
        { name: "Conference Tables", url: "#" },
        { name: "View More", url: "#" },
      ],
    },
    {
      title: "Dining Room Furniture",
      imageSrc: "./assets/fu15.jpeg",
      links: [
        { name: "Dining Tables", url: "#" },
        { name: "Dining Chairs", url: "#" },
        { name: "Buffets", url: "#" },
        { name: "Bar Carts", url: "#" },
        { name: "View More", url: "#" },
      ],
    },
    {
      title: "Living Room Furniture",
      imageSrc: "./assets/fu16.jpeg",
      alt: "Living Room Furniture",
      links: [
        { name: "Sofas", url: "#" },
        { name: "Coffee Tables", url: "#" },
        { name: "TV Stands", url: "#" },
        { name: "Bookcases", url: "#" },
        { name: "View More", url: "#" },
      ],
    },
  ];

  return (
    <>
      <div className="pagetitle">
        <h1>Furniture Trade Directory & Networking Platform</h1>
      </div>
      <div className="l1-section">
        <div className="container">
          <div className="row l1-box-wrap">
            {products.map((product, index) => (
              <div key={index} className="col-4">
                <div className="l1-box">
                  <h2 className="l1-heading">
                    <a href="#">{product.title}</a>
                  </h2>
                  <div className="d-flex">
                    <div>
                      <link rel="preload" as="image" href="#" />
                      <figure className="cp" onClick={() => {}}>
                        <img
                          src={product.imageSrc}
                          alt={product.alt}
                          width={95}
                          height={95}
                          loading="lazy"
                        />
                      </figure>
                    </div>
                    <div>
                      <ul>
                        {product.links.map((link, linkIndex) => (
                          <li key={linkIndex}>
                            <a href={link.url}>{link.name}</a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="content-page">
        {/* <p style={{ padding: '15px' }}>
                    GlobalB2BMart is the leading Furniture B2B Marketplace and Furniture Business Directory, offering comprehensive business listings of Furniture Suppliers, Manufacturers, Exporters, Wholesalers, and Traders worldwide. Our extensive and verified database includes detailed information about Furniture Companies, a diverse catalog of Furniture Products, and up-to-date Furniture Price Lists and Quotations.
                </p> */}
      </div>

      <div class="category-content">
        <h2
          style={{
            color: "orange",
            textAlign: "center",
            margin: "20px 0px",
          }}
        >
          Furniture suppliers in india | best home furniture suppliers
        </h2>
        <p class="section-content">
          Welcome to GlobalB2BMart, your most trusted website associating you
          with the furniture suppliers in India. From current plans to ergonomic
          office arrangements and handcrafted furniture pieces, we bring to you
          the organized choice of providers. At GlobalB2BMart, we put emphasis
          on quality, development, and variety, helping organizations and
          individuals source exceptional quality furniture at the correct
          prices.
        </p>

        <p class="section-heading">
          <strong>Metal Handicraft Suppliers</strong>
        </p>
        <p class="section-content">
          At GlobalB2BMart, we bring you a curated selection of metal handicraft
          suppliers offering beautiful decorative and functional metal products.
          What's perhaps most striking is the intricately designed artifacts up
          to modern metal decor from our suppliers, combining the best of
          craftsmanship with durability. We have everything from export-grade
          items to unique handmade pieces.
        </p>

        <p class="section-heading">
          <strong>Furniture Suppliers in India</strong>
        </p>
        <p class="section-content">
          India has one of the most experienced furniture suppliers in India,
          with ranges in style and material that particularly take care of each
          and every taste and necessity. At GlobalB2BMart, we link buyers to
          suppliers of traditional, modern, and modern furnishings. From
          hand-tailored wooden items to modern plans, our website ensures access
          to quality items that combine feel and functionality.
        </p>

        <p class="section-heading">
          <strong>best office furniture suppliers</strong>
        </p>
        <p class="section-content">
          The best office furniture suppliers recorded on GlobalB2BMart will
          accomplish practically everything to make the most useful work area.
          Providers offer ergonomic seats, open work areas, secluded
          arrangements, and capacity arrangements intended to upgrade solace and
          effectiveness. Our providers are taking care of every one of your
          necessities, whether it is furnishing a corporate office or home work
          area.
        </p>
        <p class="section-heading">
          <strong>best home furniture suppliers</strong>
        </p>
        <p class="section-content">
          Change your residing places with the best home furniture suppliers at
          GlobalB2BMart. From luxurious couches to strong dining tables and soft
          beds, our providers offer excellent furniture to complement your
          lifestyle. Look for thousands of designs done accurately and with
          thought to bring comfort and fashion to your house.
        </p>
        <p class="section-heading">
          <strong>Best Custom Furniture Suppliers</strong>
        </p>
        <p class="section-content">
          For the users who require exceptional plans, our best custom furniture
          suppliers make especially designed parts that fit in with your
          definite determinations. Whether you really want unique things for
          your home, office, or business, these providers have remarkable
          craftsmanship and scrupulousness. You can get furniture best fitting
          your vision with modifying options.
        </p>
        <p class="section-heading">
          <strong>best outdoor furniture suppliers </strong>
        </p>
        <p class="section-content">
          Make breathtaking outdoor spaces with products from the best outdoor
          furniture suppliers featured on GlobalB2BMart. From deck sets to grow
          seats and poolside loungers, these suppliers provide robust and
          weather-resistant furniture that combines style with functionality.
          Suitable for homes, hotels, and resorts, our outdoor furniture
          packages elevate your outdoor spaces.
        </p>
        <p class="section-heading">
          <strong>Best Kids Furniture Suppliers </strong>
        </p>
        <p class="section-content">
          Solace and security for the little ones, hence, come through offers
          from the absolute best kids furniture suppliers. These are supplies
          giving vivid beds, concentrating on tables of durable construction,
          and capacity arrangements fun sufficiently loving. In this way, our
          providers come planned, remembering kids' necessities and zeroing in
          on the quality and imagination part of the item.
        </p>
      </div>
    </>
  );
};

export default Furniture;
