import React from "react";
import "./commn.css";
import { Helmet } from "react-helmet";
const Gifts = () => {
  <Helmet>
    <title>wooden handi crafts suppliers | Religious statue suppliers.</title>
    <meta
      name="description"
      content="Discover top wooden handicrafts suppliers and religious statue suppliers for unique, high-quality products at globalb2bmart. Connect with then now.	"
    />
  </Helmet>;
  const products = [
    {
      title: "Wooden Handicrafts",
      imageSrc: "./assets/gi2.jpeg",
      links: [
        { name: "Wooden Storage Boxes", url: "#" },
        { name: "Wooden Miniatures", url: "#" },
        { name: "Wooden Sculptures", url: "#" },
        { name: "Decorative Wooden Artifacts", url: "#" },
      ],
    },
    {
      title: "Religious Statue",
      imageSrc: "./assets/gi3.jpeg",
      links: [
        { name: "Radha Krishna Sculpture", url: "#" },
        { name: "Buddha Figurine", url: "#" },
        { name: "Lord Shiva Statue", url: "#" },
        { name: "God Father Statue", url: "#" },
      ],
    },
    {
      title: "Metal Handicrafts",
      imageSrc: "./assets/gi4.jpeg",
      links: [
        { name: "Metallic Table Lamps", url: "#" },
        { name: "Metal Storage Boxes", url: "#" },
        { name: "Metallic Gifts", url: "#" },
        { name: "Metal Decorative Balls", url: "#" },
      ],
    },
    {
      title: "Acrylic Crafts",
      imageSrc: "./assets/gi1.jpeg",
      alt: "Acrylic Crafts",
      links: [
        { name: "Transparent Board", url: "#" },
        { name: "Acrylic Letters", url: "#" },
        { name: "Clear Acrylic Boxes", url: "#" },
        { name: "Acrylic Photo Frame", url: "#" },
      ],
    },
    {
      title: "Greeting Cards",
      imageSrc: "./assets/gi5.jpeg",
      links: [
        { name: "Anniversary Card", url: "#" },
        { name: "Thank You Card", url: "#" },
        { name: "Holiday Greeting Card", url: "#" },
        { name: "Custom Greeting Cards", url: "#" },
      ],
    },
    {
      title: "Gifts",
      imageSrc: "./assets/gi6.jpeg",
      links: [
        { name: "Gift Hampers", url: "#" },
        { name: "Personalized Gifts", url: "#" },
        { name: "Gift Vouchers", url: "#" },
        { name: "Luxury Gifts", url: "#" },
      ],
    },
    {
      title: "Clocks",
      imageSrc: "./assets/gi7.jpeg",
      links: [
        { name: "Digital Clock", url: "#" },
        { name: "Desk Clocks", url: "#" },
        { name: "Grandfather Clocks", url: "#" },
        { name: "Mantel Clocks", url: "#" },
      ],
    },
    {
      title: "Candles Crafts",
      imageSrc: "./assets/gi8.jpeg",
      links: [
        { name: "Tealight Candles", url: "#" },
        { name: "Scented Candles", url: "#" },
        { name: "Decorative Candles", url: "#" },
        { name: "Taper Candles", url: "#" },
      ],
    },
    {
      title: "Bead Crafts",
      imageSrc: "./assets/gi9.jpeg",
      links: [
        { name: "Glass Beads", url: "#" },
        { name: "Seed Beads", url: "#" },
        { name: "Crystal Beads", url: "#" },
        { name: "Wooden Beads", url: "#" },
      ],
    },
    {
      title: "Antiques & Collectibles",
      imageSrc: "./assets/gi10.jpeg",
      links: [
        { name: "Vintage Watches", url: "#" },
        { name: "Antique Furniture", url: "#" },
        { name: "Rare Stamps", url: "#" },
        { name: "Old Paintings", url: "#" },
      ],
    },
    {
      title: "Balloons",
      imageSrc: "./assets/gi11.jpeg",
      links: [
        { name: "Birthday Balloons", url: "#" },
        { name: "Helium Balloons", url: "#" },
        { name: "Foil Balloons", url: "#" },
        { name: "Water Balloons", url: "#" },
      ],
    },
    {
      title: "Bone, Horn & Shell Crafts",
      imageSrc: "./assets/gi12.jpeg",
      links: [
        { name: "Bone Jewelry", url: "#" },
        { name: "Horn Handicrafts", url: "#" },
        { name: "Shell Decorations", url: "#" },
        { name: "Horn Buttons", url: "#" },
      ],
    },
  ];

  return (
    <>
      <div className="pagetitle">
        <h1>Gifts & Crafts Trade Directory & Networking Platform</h1>
      </div>
      <div className="l1-section">
        <div className="container">
          <div className="row l1-box-wrap">
            {products.map((product, index) => (
              <div key={index} className="col-4">
                <div className="l1-box">
                  <h2 className="l1-heading">
                    <a href="#">{product.title}</a>
                  </h2>
                  <div className="d-flex">
                    <div>
                      <link rel="preload" as="image" href="#" />
                      <figure className="cp" onclick="#">
                        <img
                          src={product.imageSrc}
                          alt={product.alt}
                          width={95}
                          height={95}
                          loading="lazy"
                        />
                      </figure>
                    </div>
                    <div>
                      <ul>
                        {product.links.map((link, index) => (
                          <li key={index}>
                            <a href={link.url}>{link.name}</a>
                          </li>
                        ))}
                        <li>
                          <a href="#">View More</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* <div className="content-page">
        <p style={{ padding: "15px" }}>
          GlobalB2BMart is the leading Agriculture B2B Marketplace and
          Agriculture Business Directory, offering comprehensive business
          listings of Agriculture Suppliers, Manufacturers, Exporters,
          Wholesalers, and Traders worldwide. Our extensive and verified
          database includes detailed information about Agriculture Companies, a
          diverse catalog of Agriculture Products, and up-to-date Agriculture
          Price Lists and Quotations.
        </p>
      </div> */}

      <div class="category-content">
        <h2
          style={{
            color: "orange",
            textAlign: "center",
            margin: "20px 0px",
          }}
        >
          Wooden Handi Handicrafts Suppliers | Religious Statue Suppliers
        </h2>
        <p class="section-content">
          Welcome to GlobalB2BMart, your one-stop shop for sourcing exquisite
          gifts and crafts from reliable suppliers. We specialize in connecting
          buyers with trusted wooden handicraft suppliers, religious statue
          suppliers, and more to cater to diverse market demands. We ensure a
          seamless experience for businesses in the gifts and crafts industry
          with a commitment to quality and authenticity.
        </p>

        <p class="section-heading">
          <strong>Metal Handicraft Suppliers</strong>
        </p>
        <p class="section-content">
          At GlobalB2BMart, we bring you a curated selection of metal handicraft
          suppliers offering beautiful decorative and functional metal products.
          What's perhaps most striking is the intricately designed artifacts up
          to modern metal decor from our suppliers, combining the best of
          craftsmanship with durability. We have everything from export-grade
          items to unique handmade pieces.
        </p>

        <p class="section-heading">
          <strong>Religious Statue Suppliers</strong>
        </p>
        <p class="section-content">
          Explore our network of trusted religious statue suppliers who
          specialize in making beautiful statues of gods and spiritual figures.
          Available for temples, homes, and gifting, these make for precision
          and devotion products. Our suppliers ensure a premium quality and
          variety in the materials used, with brass, wood, or marble to suit
          your choices. Wooden Handi Crafts Suppliers GlobalB2BMart connects you
          with leading wooden handicrafts suppliers, offering traditional and
          contemporary wooden art pieces. From decorative items to functional
          furniture pieces, these handicrafts reflect the rich heritage and the
          skilled craftsmanship of artisans. Source eco-friendly as well as
          sustainable wooden products that resonate with timeless beauty as well
          as quality.
        </p>

        <p class="section-heading">
          <strong>Acrylic Crafts Suppliers in India</strong>
        </p>
        <p class="section-content">
          Discover a vibrant collection of acrylic products with the help of our
          Acrylic Crafts suppliers in India. These suppliers specialize in
          producing durable, lightweight, and versatile acrylic crafts,
          including photo frames, trophies, and customized decorative items.
          With high-quality materials and innovative designs, acrylic crafts are
          ideal for modern gifting and decor needs.
        </p>
        <p class="section-heading">
          <strong>Greeting Cards Suppliers in India</strong>
        </p>
        <p class="section-content">
          If you are looking for customized and high-quality greeting cards,
          then GlobalB2BMart connects you with the best greeting card suppliers
          from India. From festive cards to corporate designs, our suppliers are
          able to provide designs for all occasions through innovation and
          creativity. Make a personal touch to your gift items through
          beautifully crafted greeting cards.
        </p>
        <p class="section-heading">
          <strong>Candles Crafts Suppliers</strong>
        </p>
        <p class="section-content">
          Illuminate your spaces with beautiful creations from our Candle Crafts
          suppliers. Whether you need decorative candles, scented varieties, or
          specialty designs for events, the suppliers provide a wide range to
          match your preferences. Crafted with care and innovation, these
          candles make perfect gifts, decorations, or personal use.
        </p>
        <p class="section-heading">
          <strong>Bead Crafts Suppliers</strong>
        </p>
        <p class="section-content">
          GlobalB2BMart connects you with the experienced bead crafts suppliers
          who create highly beautiful designs using high-quality beads. Bead
          crafts are used to create jewelry or home decor items, where it adds a
          touch of elegance and creativity. Product Details: These products suit
          retail stores, boutiques, and export markets, showcasing skilled
          artistry and premium materials.
        </p>
      </div>
    </>
  );
};

export default Gifts;
